<template>
  <div class="page-content">
    <page-breadcrumb title="Report" class="mb-2" />
    <div class="d-flex align-items-center">
      <form-generator :model="filter" :schema="filter_schema" style="width: 70%"/>
      <b-button class="ml-1" variant="success" size="sm" @click="showOverallReport">View Overall Report</b-button>
      <b-button class="ml-1" variant="success" size="sm" @click="showCommentReport">View Comments Report</b-button>
    </div>
    <data-table-ssr v-show="isShowOverallReport" id="group_list" ref="group_list"
      :columns="fields" :get-list-fn="getListOverall" :to-edit-fn="toEditPage"
    />
    <data-table-ssr v-show="isShowCommentReport" id="group_list" ref="comment_group_list"
      :columns="comment_fields" :get-list-fn="getListComment"
    />
  </div>
</template>

<script>
import service from '../service'
import {STATUS_OPTIONS} from '../constants'
import _ from 'lodash'

const filter_schema = [
  { 
    cols: 4, fields: [{ field: 'host', label: 'Host', input_type: 'select', options: [] }] 
  },
  {
    cols: 4, fields: [{ field: 'subject_key', label: 'Talkshow' }]
  },
  {
    cols: 4, fields: [{ field: 'date', label: 'Talkshow date', input_type: "date_range" }]
  }
]
const comment_fields = [
  { label: 'Host', field: 'host'},
  { label: 'Talkshow', field: 'subject'},
  { label: 'Comment', field: 'message'},
]
export default {
  data(){
    return{
      total: 0,
      filter_schema,
      filter: {
        date: null,
      },
      current_limit: null,
      isShowOverallReport: false,
      isShowCommentReport: false,
      comment_fields,
      fields: [
        { label: 'Title', field: 'subject'},
        { label: 'Status', field: 'status', input_type: 'select', options: STATUS_OPTIONS },
        { label: 'Date', field: 'date', input_type: 'date' },
        { label: 'Start time', field: 'time_from' },
        { label: 'End time', field: 'time_to' },
        { label: 'Total bookings', field: 'total_bookings'},
        { label: 'Price', field: 'price'},
        { label: 'Total gem', field: 'total_gem'},
        { label: 'Host', field: 'host'},
        { label: 'Content Rating', field: 'content_score'},
        { label: 'Experience Rating', field: 'experience_score'},
        { label: 'Host Rating', field: 'host_score'}, 
        { label: 'Check-in time', field: 'first_in', styleMethod: this.firstInAlert}, 
        { label: 'Check-out time', field: 'last_out', styleMethod: this.lastOutAlert},
        { label: 'Acitivity time', field: 'activity_time'},  
      ]
    }
  },
  created(){
    this.getHostList();
  },
  watch: {
    filter: {
      handler(){
        if (this.filter.subject_key){
          this.filter.subject = {$regex: this.filter.subject_key, "$options": 'i'}
          delete this.filter.subject_key
        }
        else{
          delete this.filter.subject
        }
        this.$refs.group_list.getList();
        this.$refs.comment_group_list.getList();
      },
      deep: true
    }
  },
  methods:{
    async getListOverall({ limit, page }) {
      this.current_limit = limit;
      let response_data = await service.getReport({
        query: this.removeEmptyFields(this.filter),
        page, limit
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        this.total = total;
      }
      return { list, total };
    },
    async getListComment({ limit, page }) {
      this.current_limit = limit;
      let response_data = await service.getCommentReport({
        query: this.removeEmptyFields(this.filter),
        page, limit
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        this.total = total;
      }
      return { list, total };
    },
    toEditPage({ _id }) {
      this.$router.push({ name: 'native-talk-report-detail', params: { id: _id } });
    },
    async getHostList(){
      let response = await service.getHostList();
      if (response){
        let host_options = response.hosts.map((host) => {
          return {text: host, value: host}
        });
        host_options.unshift({text: "All", value: null});
        this.filter_schema[0].fields[0].options = host_options;
      }
    },
    removeEmptyFields(obj){
      return _.omitBy(obj, (v) => _.isNil(v) || v === '');
    },
    async showOverallReport(){
      this.isShowOverallReport = true;
      this.isShowCommentReport = false;
    },
    async showCommentReport(){
      this.isShowOverallReport = false;
      this.isShowCommentReport = true;
    },
    firstInAlert(item){
      let time_from = item.time_from ? item.time_from.replace(":","") : "";
      let first_in = item.first_in ? item.first_in.replace(":","") : "";
      if (Number(time_from) < Number(first_in)){
        return {color: "red"}
      }
      return ""
    },
    lastOutAlert(item){
      let time_to = item.time_to ? item.time_to.replace(":","") : "";
      let last_out = item.last_out ? item.last_out.replace(":","") : "";
      if (Number(last_out) < Number(time_to)){
        return {color: "red"}
      }
      return ""
    }
  }
}
</script>
